<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
    v-if="userId && admin"
  >
    <v-dialog
      v-model="modal"
      max-width="790"
    >
      <v-card>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="11"
                >
                  <v-select
                    label="Droit"
                    class="purple-input"
                    v-model="selectedTheme"
                    :items="themes"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required, ]"
                  ></v-select>
                  <v-select
                    v-model="selectedBoxe"
                    :items="boxes"
                    item-text="name"
                    item-value="id"
                    label="Boxe"
                    :rules="[rules.required, ]"
                  ></v-select>
                  <v-select
                    v-model="selectedLawyer"
                    :items="lawyers"
                    item-text="name"
                    item-value="id"
                    label="Avocat"
                    :rules="[rules.required, ]"
                  ></v-select>
                  <v-textarea
                    solo
                    name="description"
                    label="Description"
                    v-model="description"
                    height="70px"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-select
                    label="Date de la consultation"
                    v-model="selectedDate"
                    :items="days"
                    item-text="id"
                    item-value="date"
                    :rules="[rules.required, ]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Heure de début"
                    type="time"
                    v-model="timeStart"
                    :rules="[rules.required, ]"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Heure de fin"
                    type="time"
                    v-model="timeEnd"
                    :rules="[rules.rangeTime, ]"
                    required
                  />
                </v-col>
              </v-row>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  text
                  color="red"
                  @click="deleteConsultation(consultation)"
                >Supprimer</v-btn>
                <v-btn
                  color="#174282"
                  class="mr-2"
                  :disabled="!valid"
                  @click="updateConsultation"
                >Modifier</v-btn>
              </v-col>
              <v-col
                cols="12"
                class="text-center"
              ></v-col>
            </v-container>
          </v-form>
        </v-card>
    </v-dialog>
    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <base-material-card color="#174282">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Liste des inscrits
            </div>
          </template>
          <v-card-actions>
            <v-row>
              <v-col
                offset-sm=3
                md=3
                cols=12
              >
                <v-btn @click="scrollMeTo('conferences')" class="primary">
                  Voir les ateliers
                </v-btn>
              </v-col>
              <!--<v-col
                md=3
                cols=12
              >
                <v-btn @click="scrollMeTo('consultations')" class="primary">
                  Voir les consultations
                </v-btn>
              </v-col>-->
            </v-row>
          </v-card-actions>
          <v-card>
            <v-card-text>
               Taux de remplissage global des ateliers : {{ getGlobalTaux() }}%
            </v-card-text>
          </v-card>
          <v-row v-if="element==='conferences'"
            id="conferences"
            ref="conferences"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-card
                class="mx-auto my-12"
                max-width="500"
              >
                <v-card-title>Jeudi 30 septembre</v-card-title>
                <v-card>
                  <v-card-text>
                    <p>Taux de remplissage : {{ getDailyTaux('jeudi') }}%</p>
                  </v-card-text>
                </v-card>
                <v-divider class="mx-4"></v-divider>
                <div
                  v-for="conference in conferences.jeudi"
                  :key="conference.id"
                >
                  <v-card-text>
                    <h3>{{ conference.name }}</h3>
                    <br>
                    <p>{{ conference.description }}</p>
                  </v-card-text>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>Voir les inscrits ({{ conference.subCount.length }})</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p v-if="conference.subCount.length < 1">
                          Aucun inscrit à cet atelier
                        </p>
                        <p
                          v-for="user in conference.subCount"
                          :key="user.id"
                        >
                          {{user.USR_Name}} {{user.USR_Surname}} -- {{user.USR_Email}}
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-card
                class="mx-auto my-12"
                max-width="500"
              >
                <v-card-title>Vendredi 1 octobre</v-card-title>
                <v-card>
                  <v-card-text>
                    <p>Taux de remplissage : {{ getDailyTaux('vendredi') }}%</p>
                  </v-card-text>
                </v-card>
                <v-divider class="mx-4"></v-divider>
                <div
                  v-for="conference in conferences.vendredi"
                  :key="conference.id"
                >
                  <v-card-text>
                    <h3>{{ conference.name }}</h3>
                    <br>
                    <p>{{ conference.description }}</p>
                  </v-card-text>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>Voir les inscrits ({{ conference.subCount.length }})</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p v-if="conference.subCount.length < 1">
                          Aucun inscrit à cet atelier
                        </p>
                        <p
                          v-for="user in conference.subCount"
                          :key="user.id"
                        >
                          {{user.USR_Name}} {{user.USR_Surname}} -- {{user.USR_Email}}
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="element === 'consultations'"
            id="consultations"
            ref="consultations"
          >
          
            <v-col
              cols="12"
              md="6"
            >
              <v-card
                class="mx-auto my-12"
                max-width="500"
              >
                <v-card-title>Jeudi 30 septembre</v-card-title>
                <v-divider class="mx-4"></v-divider>
                <div
                  v-for="theme in themes.filter(
                    function(theme){
                      return consultations.filter(
                        function(item){
                          return item.Date === '2020-10-05' && item.FFD_ID == theme.id
                          }
                        ).length
                      }
                    )"
                  :key="theme.id"
                >
                  <v-card-text>
                    {{ theme.name }}
                  </v-card-text>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>Voir les consultations disponibles</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div
                          v-for="consultation in consultations.filter(function(item){ return item.Date === '2020-10-05' && item.FFD_ID === theme.id })"
                          :key="consultation.id"
                        >
                          <v-card-text>
                            <h3>{{ consultation.TimeStart.split(':')[0] }}h{{ consultation.TimeStart.split(':')[1] }}-{{ consultation.TimeEnd.split(':')[0] }}h{{ consultation.TimeEnd.split(':')[1] }}</h3>
                            <br>
                            <p>Maître {{ consultation.AVO_Name }}</p>
                          </v-card-text>
                          <v-card-text>
                            <p v-if="consultation.USR_ID === '0'">
                              Aucun inscrit à cette consultation
                            </p>
                            <p v-else>
                              {{consultation.USR_Name}} {{consultation.USR_Surname}} -- {{consultation.USR_Email}}
                            </p>
                            <v-btn
                             text
                             color="green"
                             @click="openModal(consultation)"
                            >
                            Modifier
                            </v-btn>  
                          </v-card-text>
                          <v-divider class="mx-4"></v-divider>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-card
                class="mx-auto my-12"
                max-width="500"
                v-if="consultations"
              >
                <v-card-title>Vendredi 1 octobre</v-card-title>
                <v-divider class="mx-4"></v-divider>
                <div
                  v-for="theme in themes.filter(
                    function(theme){
                      return consultations.filter(
                        function(item){
                          return item.Date === '2020-10-06' && item.FFD_ID == theme.id
                          }
                        ).length
                      }
                    )"
                  :key="theme.id"
                >
                  <v-card-text>
                    {{ theme.name }}
                  </v-card-text>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>Voir les consultations disponibles</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div
                          v-for="consultation in consultations.filter(function(item){ return item.Date === '2020-10-06' && item.FFD_ID === theme.id })"
                          :key="consultation.id"
                        >
                          <v-card-text>
                            <h3>{{ consultation.TimeStart.split(':')[0] }}h{{ consultation.TimeStart.split(':')[1] }}-{{ consultation.TimeEnd.split(':')[0] }}h{{ consultation.TimeEnd.split(':')[1] }}</h3>
                            <br>
                            <p>Maître {{ consultation.AVO_Name }}</p>
                          </v-card-text>
                          <v-card-text>
                            <p v-if="consultation.USR_ID === '0'">
                              Aucun inscrit à cette consultation
                            </p>
                            <p v-else>
                              {{consultation.USR_Name}} {{consultation.USR_Surname}} -- {{consultation.USR_Email}}
                            </p>
                            <v-btn
                             text
                             color="green"
                             @click="openModal(consultation)"
                            >
                            Modifier
                            </v-btn>  
                          </v-card-text>
                          <v-divider class="mx-4"></v-divider>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { httpGet, httpPost } from '@/plugins/axios'
  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        snackbar: false,
        themes: [],
        text: '',
        render: 0,
        timeout: 2000,
        valid: true,
        modal: false,
        userId: null,
        admin: null,
        consultations: null,
        FFC_ID: 0,
        days: [
          { id: 'jeudi 30 septembre', date: '2020-10-05' },
          { id: 'vendredi 1 octobre', date: '2020-10-06' },
        ],
        selectedDate: null,
        timeStart: '',
        timeEnd: '',
        name: '',
        selectedTheme: null,
        lawyers: [],
        selectedLawyer: null,
        selectedBoxe: null,
        boxes: [],
        place: '',
        description: '',
        element: 'conferences',
        rules: {
          required: value => !!value || 'Requis.',
          rangeTime: value => value >= this.timeStart || 'La date de fin doit être après la date de début',
        },
         conferences: {
          jeudi: [
            {
              id: 1,
              name: '08h30-10h00 Choisir mes associés et ma forme juridique : 2 piliers clés de mon entreprise',
              description: 'Votre Avocat vous conseille dans le choix de la structure et de vos partenaires en fonction de votre activité et de vos objectifs.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 2,
              name: '10h00-11h30 Handicap et droit pour enrichir l\'entreprise',
              description: 'Au-delà du nouveau dispositif du 1er janvier pour promouvoir l\'emploi des travailleurs en situation de handicap ,l\'apport du handicap dans l\'entreprise.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 3,
              name: '11h30-13h00 Quelle stratégie de financement pour accélérer mon développement ?',
              description: 'L\'Avocat vous conseille sur les différentes solutions de financement au regard de la situation particulière et des projets de votre entreprise.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 4,
              name: '13h00-14h30 Cybersécurité',
              description: 'Le RGPD est aussi un outil de sécurisation et de développement que votre Avocat vous aidera à mettre en place et à faire évoluer. ',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 5,
              name: '14h30-16h00 La fiducie, ou reine des sûretés',
              description: 'L\'Avocat a le monopole aux côtés des établissements de crédit de la fiducie outil indispensable à l\'emprunteur pour obtenir plus et à des meilleures conditions',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 6,
              name: '16h00-17h30 La reprise d\'entreprises à la barre  ',
              description: 'Les avantages, les inconvénients et les risques expliqués par votre Avocat.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
          ],
          vendredi: [
            {
              id: 7,
              name: '08h30-10h00 Penser le conflit autrement',
              description: 'Présentation des différents modes de règlements amiables des différends.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 8,
              name: '10h00-11h30 Prévention et nouvelle procédure de traitement de sortie de crise',
              description: 'Quand et comment les utiliser pour prévenir les risques et sortir de la crise ?',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 9,
              name: '11h30-13h00 Mon bail commercial',
              description: 'Le bail commercial est un contrat essentiel que votre Avocat sécurisera.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 10,
              name: '13h00-14h30 La gestion de crise ',
              description: 'L\'Avocat vous accompagne pour mettre en place un plan de crise.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 11,
              name: '14h30-16h00 Patrimoine du dirigeant ',
              description: 'Panel des différentes protections à mettre en œuvre pour le chef d\'entreprise.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 12,
              name: '16h00-17h30 Télétravail',
              description: 'Avantages du télétravail riment avec risques à sécuriser avec votre Avocat.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
          ],
        },
      }
    },
    mounted () {
      this.userId = this.$store.state.userId
      this.admin = this.$store.state.isAdmin
      this.getData()
      this.getThemes()
      this.getBoxes()
      this.getLawyers()
    },
    methods: {
      getGlobalTaux () {
          let max = 0,
              participants = 0;
          this.conferences.jeudi.forEach(
            function(conference){
              max += conference.maximum
              participants += conference.subCount.length
            }
          )
          this.conferences.vendredi.forEach(
            function(conference){
              max += conference.maximum
              participants += conference.subCount.length
            }
          )      
          return (participants/max*100).toFixed(2)
      },
      getDailyTaux (day) {
          let max = 0,
              participants = 0;
          this.conferences[day].forEach(
            function(conference){
              max += conference.maximum
              participants += conference.subCount.length
            }
          )
          return (participants/max*100).toFixed(2)
      },
      openModal (consultation) {
        this.FFC_ID = consultation.FFC_ID
        this.timeStart = consultation.TimeStart
        this.timeEnd = consultation.TimeEnd
        this.selectedBoxe = consultation.FBX_ID
        this.selectedTheme = consultation.FFD_ID
        this.selectedLawyer = consultation.AVO_ID
        this.description = consultation.Description
        this.selectedDate = consultation.Date
        this.modal = true
      },
      async getThemes () {
        const response = await httpGet('getThemes')
        this.themes = []
        response.Themes.forEach(theme => {
          this.themes.push({ id: theme.FFD_ID, name: theme.FFD_Name })
        })
      },
      async getBoxes () {
        const response = await httpGet('getBoxes')
        this.boxes = []
        response.boxes.forEach(boxe => {
          this.boxes.push({ id: boxe.FBX_ID, name: boxe.FBX_Name })
        })
      },
      async getLawyers () {
        const response = await httpGet('getLawyers')
        this.lawyers = []
        response.lawyers.forEach(lawyer => {
          this.lawyers.push({ id: lawyer.AVO_ID, name: lawyer.AVO_Name })
        })
      },
      scrollMeTo (refName) {
        this.element = refName
        /*
        var element = this.$refs[refName]
        var top = element.offsetTop
        window.scrollTo(0, top)
        */
      },
      async getData () {
        const consultations = await httpGet('getConsultations')
        this.consultations = consultations.Consultations
        this.conferences.jeudi.forEach(
          async (conference) => {
            const users = await httpPost('getSubByConfId', {
              confId: conference.id,
            })
            conference.subCount = []
            users.Count.forEach(user => {
              conference.subCount.push({
                USR_Name: user.USR_Name,
                USR_Surname: user.USR_Surname,
                USR_Email: user.USR_Email,
              })
            })
          },
        )
        this.conferences.vendredi.forEach(
          async (conference) => {
            const users = await httpPost('getSubByConfId', {
              confId: conference.id,
            })
            conference.subCount = []
            users.Count.forEach(user => {
              conference.subCount.push({
                USR_Name: user.USR_Name,
                USR_Surname: user.USR_Surname,
                USR_Email: user.USR_Email,
              })
            })
          },
        )
      },
      displaySnack (message) {
        this.text = message
        this.snackbar = true
      },
      async subcribeToConf (id) {
        await httpPost('subToConf', {
          userId: this.$store.state.userId,
          confId: id,
        })
        this.getData()
        this.displaySnack('Vous êtes bien enregistré pour cet atelier.')
      },
      async updateConsultation () {
        await httpPost('updateConsultation', {
           conId: this.FFC_ID,
           theme: this.selectedTheme,
           boxe: this.selectedBoxe,
           description: this.description,
           lawyer: this.selectedLawyer,
           day: this.selectedDate, 
           start: this.timeStart,
           end: this.timeEnd
        }).then(this.getData())
        this.getData()
        this.modal = false
        this.displaySnack('Consultation modifiée avec succés.')
      },
      async deleteConsultation () { 
        await httpPost('deleteConsultation', {
            conId: this.FFC_ID
        }).then(this.getData())
        this.getData()
        this.modal = false
        this.displaySnack('Consultation supprimée avec succés.')
      },
      async unSubcribeToConf (id) {
        await httpPost('unSubToConf', {
          userId: this.$store.state.userId,
          confId: id,
        }).then(this.getData())
        this.displaySnack('Votre réservation pour cet atelier a bien été annulée.')
      },
      async subcribeToConsultation (id) {
        await httpPost('subToConsultation', {
          userId: this.$store.state.userId,
          consultationId: id,
        })
        this.getData()
        this.displaySnack('Vous avez reservé cette consultation avec succés.')
      },
      async getSubByConf (id) {
        await httpPost('getSubByConfId', {
          confId: id,
        })
      },
      async unSubcribeToConsultation (id) {
        await httpPost('unSubToConsultation', {
          consultationId: id,
        })
        this.getData()
        this.displaySnack('Votre réservation pour cette consultation a bien été annulée.')
      },
    },
  }
</script>
